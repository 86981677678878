import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

export default function AccordionUsage() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
          How many students are there in a Batch?
        </AccordionSummary>
        <AccordionDetails>
        There are only 5-10 students in a batch. So, that each student can get personal attention.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
        How will I solve my doubts?
        </AccordionSummary>
        <AccordionDetails>
        All your doubts will be solved in the class itself. where you can ask your doubts with your faculty
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
       How are the classes conducted?
        </AccordionSummary>
        <AccordionDetails>
        Our class is conducted through our own Website
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
       What if I miss the class?
        </AccordionSummary>
        <AccordionDetails>
        Recording of your live class gets uploaded after the class gets over, so that you can revise it any time.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
       Do you provide certificate and is your certificate Valuable?
        </AccordionSummary>
        <AccordionDetails>
        Yes, You will be provided certificate after the successful completion of course (including examination), which is Valuable and recognizable all across the world.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
       How are the classes conducted?
        </AccordionSummary>
        <AccordionDetails>
        Our class is conducted through our own Website
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
      What if I miss the class?
        </AccordionSummary>
        <AccordionDetails>
        Recording of your live class gets uploaded after the class gets over, so that you can revise it any time.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          className='list-decimal list-inside text-lg font-medium text-[#9B9696] leading-10 px-4 mb-10'
        >
     Do you provide certificate and is your certificate Valuable?
        </AccordionSummary>
        <AccordionDetails>
        Yes, You will be provided certificate after the successful completion of course (including examination), which is Valuable and recognizable all across the world.
        </AccordionDetails>
      </Accordion>

    </div>
  );
}
