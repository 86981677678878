import React from 'react'

const About = () => {
  return (
    <div>
         <div className='mt-12 flex'>
      {/* <h2 className='text-[#F17806] mx-auto justify-center text-[42px]  leading-10'>About Fortune Talk</h2> */}
      <h2 className='text-[#F17806] mx-auto text-center item-center  justify-center text-[42px]  leading-10 p-3'>About Fortune Talk</h2>
    </div>
    <div className='m-3 md:m-8 p-3 md:p-8  leading-7'>
        <p>FortuneTalk is your go-to destination for astrology enthusiasts and those seeking guidance in their lives. This website offers a wide range of services, making it the best astrology website for online astrology predictions. Here's a brief overview of what Fortune Talk has to offer:</p>
        <br />
        <p>
        <b>Astrology Predictions:</b> Fortune Talk connects you with the best astrologers from India who can provide insights into your future. Whether you're looking for answers related to marriage, love life, career, or health, you can get accurate predictions through astrology Kundli readings. These predictions are available over call, chat, and through detailed reports, making it convenient and accessible for all.
        </p>
        <br />

        <p>
<b>Talk to Astrologer on Call/Chat:</b> With Fortune Talk, you can have a one-on-one conversation with experienced astrologers over a phone call. This personalized interaction allows you to address your concerns, ask questions, and gain a deeper understanding of your life's path. 
        </p>
        <br />

        <p>
<b>Astrology Learning Platform:</b> Fortune Talk also offers a unique learning platform for individuals interested in astrology. Here, you can explore the fascinating world of astrology, enhance your knowledge, and even earn by sharing your expertise. It's an ideal space for both beginners and seasoned astrologers to expand their skills.
        </p>
        <br />

        <p>
        <b>Query and Report Services:</b> If you have specific questions or need a comprehensive astrological report, Fortune Talk has you covered. You can submit your queries or request in-depth reports that delve into various aspects of your life, helping you make informed decisions.
        </p>
        <br />
        <p>
<b>Convenient and Reliable: </b> Fortune Talk boasts a team of renowned astrologers, ensuring that you receive accurate and reliable predictions. The platform is user-friendly, making it easy for anyone to access the insights they seek.
        </p> <br />

        <p>
        Fortune Talk is a versatile platform that caters to the diverse needs of individuals looking for astrology-related services. Whether you're seeking predictions for various life aspects, personal consultations with astrologers, or a platform to learn and share your astrological knowledge, Fortune Talk has something to offer for everyone. <br />
        </p>
    </div>
    </div>
  )
}

export default About