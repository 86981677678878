import * as actionTypes from "../actionTypes";

export const onCutomerLogin = (payload) => ({
    type: actionTypes.ON_CUSTOMER_LOGIN,
    payload,
});


export const onOtpVerification = (payload) => ({
    type: actionTypes.ON_OTP_VERIFICATION,
    payload,
});
