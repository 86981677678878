import { CLOSE_OTP_MODAL, OPEN_OTP_MODAL, SET_IS_LOGGED } from "../actionTypes";

const initialState = {
    isOtpModalOpen: false,
    isOtpModalclose: false,
    isLogged: false,

  };
  
  const auth = (state = initialState, action) => {
    switch (action.type) {

      case OPEN_OTP_MODAL:
        return {
          ...state,
          isOtpModalOpen: true,
        };

      case CLOSE_OTP_MODAL:
        return {
          ...state,
          isOtpModalOpen: true,
        };

      case SET_IS_LOGGED:
        return {
          ...state,
          isLogged: true,
        };
        
      default:
        return state;
    }
  };
  export default auth;