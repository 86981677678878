import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import logo from "../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { MuiOtpInput } from 'mui-one-time-password-input'
import * as AuthActions from "../../redux/Actions/authActions.js";
import { useDispatch } from "react-redux";

export default function Login({ isOpen, onClose }) {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);

  const handleSendOTP = (event) => {
    event.preventDefault();
    setIsOtpModalOpen(true); // Open OTP modal
    setResendDisabled(true); // Start resend timer
    setResendTimer(60); // Reset the timer
    console.log('OTP sent to:', phoneNumber);
  };

  const onLoginSuccess = () => {
    navigate('/')
  }

  const verifyOtp = (event) => {
    event.preventDefault();
    // dispatch(AuthActions.onOtpVerification({otp:'otpData'}))
    dispatch(AuthActions.onOtpVerification({
      otpData: {
        phoneNumber: `${phoneNumber}`,
        callingCode: "+91",
      }, setIsOtpModalOpen, onClose, onLoginSuccess
    }));
    console.log({
      phoneNumber: `${phoneNumber}`,
      callingCode: "+91",
    });
    console.log('OTP verified:', otp);
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    // Ensure only numbers are entered
    if (/^[0-9]?$/.test(value)) {
      newOtp[index] = value; // Update the specific OTP input
      setOtp(newOtp);

      // Move to the next input automatically
      if (value && index < 5) {
        const nextInput = document.getElementById(`otp-input-${index + 1}`);
        if (nextInput) nextInput.focus();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(AuthActions.onCutomerLogin({ phoneNumber, setIsOtpModalOpen }));
    console.log("submitted");
  };

  const handleChange = (newValue) => {
    setOtp(newValue)
  }

  const handleResendClick = () => {
    handleSendOTP();
  };

  useEffect(() => {
    let intervalId;
    if (resendTimer > 0 && resendDisabled) {
      intervalId = setInterval(() => {
        setResendTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (resendTimer === 0) {
      setResendDisabled(false); // Enable resend after timer runs out
    }
    
    return () => clearInterval(intervalId);
  }, [resendTimer, resendDisabled]);

  
  return (
    <section>
      <div className={`fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'} bg-gray-800 bg-opacity-70`}>
        <div className="flex flex-col rounded-lg overflow-hidden w-full max-w-md shadow-lg bg-white">
          <div className="flex items-center justify-between bg-[#F27806] p-4">
            <div className="flex items-center">
              <img className="h-10 w-10" src={logo} alt="Logo" />
              <h2 className="text-xl font-bold text-white ml-2">Fortune Talk</h2>
            </div>
            <button onClick={onClose} className="text-white hover:text-gray-300">
              <AiOutlineClose size={24} />
            </button>
          </div>

          {/* Phone Number Input */}
          {!isOtpModalOpen ? (
            <div className="flex flex-col justify-center p-6">
              <h2 className="text-xl text-[#F37905] text-center mb-2">Let's Start</h2>
              <p className="text-md text-center mb-4">Enter your mobile number to login</p>
              <form className="space-y-4">
                {/* onSubmit={handleSendOTP} */}
                <div className="flex border border-gray-300 rounded-lg overflow-hidden">
                  <span className="flex items-center justify-center bg-gray-200 border-r border-gray-300 text-sm px-4">+91</span>
                  <input
                    type="tel"
                    placeholder="Enter mobile number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="flex-grow border-none focus:outline-none px-4 py-3"
                    required
                  />
                </div>
                <p className="text-[#A3A3A3] text-sm text-center">By continuing, you agree to our Terms of Use & Privacy Policy</p>
                <div className="flex justify-center">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="w-full max-w-xs flex items-center justify-center rounded-lg bg-gradient-to-r from-[#F27806] to-[#E15602] text-white py-2 font-semibold transition duration-300 hover:bg-[#E15602]"
                  >
                    Send OTP
                  </button>
                </div>
              </form>
            </div>
          ) : (
            // OTP Input
            <div className="flex flex-col justify-center p-6">
              <h2 className="text-xl text-[#F37905] text-center mb-0">Enter OTP</h2>
              <p className="text-md text-center mb-4">Please enter the 4-digit OTP sent to your mobile</p>
              <div className="text-md text-center flex justify-center  mx-4 my-4" >
                <MuiOtpInput
                  value={otp}
                  onChange={handleChange}
                  sx={{
                    '& input': {
                      width: '20px', // Adjust width
                      height: '16px', // Adjust height
                      fontSize: '1rem', // Adjust font size
                      boxShadow: '4px 4px 9px rgba(3, 3, 3, 0.2)',
                      borderRadius: '5px',
                    },
                  }}
                  inputProps={{
                    inputMode: 'numeric', // Suggest numeric keyboard on mobile
                    pattern: '[0-9]*', // For mobile browsers
                  }}
                />
              </div>
              {resendDisabled ? (
                <p className="text-center text-sm text-gray-500">Resend OTP in {resendTimer} seconds</p>
              ) : (
                <button onClick={handleResendClick} className="text-center text-[#F27805] hover:underline">Resend OTP</button>
              )}
              <div className="flex justify-center mt-2">
                <button
                  type="submit"
                  onClick={verifyOtp}
                  className="w-full max-w-xs flex items-center justify-center rounded-lg bg-gradient-to-r from-[#F27806] to-[#E15602] text-white py-2 font-semibold transition duration-300 hover:bg-[#E15602]"
                >
                  Verify OTP
                </button>
              </div>

            </div>
          )}
        </div>
      </div>
    </section>
  );
}


// import React, { useState, useEffect } from "react";
// import numerologist from "../../assets/image/numerologist.png";
// import logo from "../../assets/image/logo.png";
// import Otp from "./Otp";
// import { useNavigate } from "react-router-dom";

// export default function Login({ isOpen, onClose }) {
//   const navigate = useNavigate();

//   const [phoneNumber, setPhoneNumber] = useState('');
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [userData, setUserData] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [resendDisabled, setResendDisabled] = useState(false);
//   const [resendTimer, setResendTimer] = useState(60);
//   const [startTimer, setStartTimer] = useState(false);

//   const handleSendOTP = async (event) => {
//     event.preventDefault();
//     try {
//       setLoading(true);
//       const response = await fetch(`api/v1/webUser/otp?number=${phoneNumber}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setStartTimer(true);
//         setUserData(data);
//         setIsModalOpen(true);
//       } else {
//         console.error('Login failed');
//       }
//     } catch (error) {
//       console.error('Error occurred while logging in:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const verifyOtp = async (enteredOTP) => {
//     try {
//       const response = await fetch(`api/v1/webUser/verify_OTP?phone=${userData.user.phone}&otp=${enteredOTP}`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${userData.jwt.token}`
//         }
//       });

//       if (response.ok) {
//         const data = await response.json();
//         setUserData(data);
//         setLocalStorage(data);
//         navigate(`/register/${userData.user.id}`);
//       } else {
//         console.error('OTP verification failed');
//       }
//     } catch (error) {
//       console.error('Error occurred while verifying OTP:', error);
//     }
//   };

//   const handleChangeMobile = () => {
//     setIsModalOpen(false);
//   };

//   useEffect(() => {
//     let intervalId;
//     if (resendTimer > 0 && resendDisabled) {
//       intervalId = setInterval(() => {
//         setResendTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     } else if (resendTimer === 0 && resendDisabled) {
//       setResendDisabled(false);
//       setResendTimer(60);
//       clearInterval(intervalId);
//     }

//     return () => clearInterval(intervalId);
//   }, [startTimer, resendTimer, resendDisabled]);

//   const handleResendClick = async () => {
//     // Implement your resend OTP logic here
//     setResendDisabled(true);
//   };

//   const setLocalStorage = (data) => {
//     localStorage.setItem('token', data.jwt.token);
//   };

//   return (
//     <section>
//      <div className={`modal-content fixed inset-0 z-50 flex items-center justify-center ${isOpen ? '' : 'hidden'} bg-gray-800 bg-opacity-70`}>

//         <div className="flex flex-col lg:flex-row rounded-3xl overflow-hidden w-full max-w-4xl shadow-lg">
//           <div className="hidden lg:block lg:w-1/2">
//             <img className="h-full w-full object-cover" src={numerologist} alt="Numerologist" />
//           </div>

//           <div className="flex flex-col justify-center bg-white lg:w-1/2 p-8">
//             <div className="flex items-center justify-center mb-6 bg-[#F27806] p-4 rounded-lg">
//               <img className="h-16 w-16" src={logo} alt="Logo" />
//               <h2 className="text-2xl font-bold text-white ml-2">Fortune Talk</h2>
//             </div>

//             <h2 className="text-2xl text-[#F37905] text-center mb-4">Get Started with Fortune Talk!</h2>
//             <p className="text-lg text-center mb-6">Enter your mobile number to Login</p>

//             <form onSubmit={handleSendOTP} className="space-y-5">
//               <div className="flex border border-gray-300 rounded-lg overflow-hidden">
//                 <span className="flex items-center justify-center bg-gray-200 border-r border-gray-300 text-sm px-4">+91</span>
//                 <input
//                   type="tel"
//                   placeholder="Enter mobile number"
//                   value={phoneNumber}
//                   onChange={(e) => setPhoneNumber(e.target.value)}
//                   className="flex-grow border-none focus:outline-none px-4 py-3"
//                   required
//                 />
//               </div>
//               <p className="text-[#A3A3A3] text-sm text-center">By continuing, you agree to our Terms of Use & Privacy Policy</p>
//               <div className="flex justify-center">
//                 <button
//                   type="submit"
//                   className="w-full max-w-xs flex items-center justify-center rounded-lg bg-gradient-to-r from-[#F27806] to-[#E15602] text-white py-2 font-semibold transition duration-300 hover:bg-[#E15602]"
//                 >
//                   Send OTP
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>

//       <Otp isOpenOtp={isModalOpen} onCloseOtp={() => setIsModalOpen(false)} verifyOtp={verifyOtp} handleChangeMobile={handleChangeMobile} handleResendClick={handleResendClick} resendDisabled={resendDisabled} resendTimer={resendTimer} />
//     </section>
//   );
// }
