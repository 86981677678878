import React, { useEffect, useState, useRef } from "react";
import { Menu, X } from "lucide-react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo.png";
import Wallet from "../../assets/image/wallethi.png";
import walletBg from "../../assets/image/walletwhitebg.png";
import Login from "../login/Login";  // Assuming this is a modal component
import Swal from "sweetalert2";
import './navstyle.css';
import * as CustomerAction from "../../redux/Actions/customerActions.js";
import { connect } from "react-redux";
import { showNumber } from "../../utils/services.js";

const menuItems = [
  { name: "Home", href: "/" },
  { name: "Call/Chat", href: "/chat" },
  { name: "Blog", href: "/blog" },
  { name: "Free Insights", href: "#" },
  { name: "Fortune Store", href: "/fortune-store" },
  { name: "Learn & Earn", href: "/courses" },
];

function Navbar({ isLoggedIn, customerData }) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);  // Modal ref

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You will be logged out!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, log me out!',
      customClass: { popup: 'small-modal' },
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate('/login');
      }
    });
  };

  const handleLoginSuccess = () => {
    closeModal();
  };

  // Function to close modal on click outside
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    // Add event listener when modal is open
    if (isMenuOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    // Cleanup event listener when modal is closed
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMenuOpen]); // Only re-run when modal state changes

  return (
    <div className="relative w-full bg-gradient-to-r from-[#F77C02] to-[#E36D17]">
      <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-3 sm:px-6 lg:px-8">
        <Link to="/" className="flex items-center">
          <img src={Logo} alt="Logo" className="h-[56px] w-[56px]" />
          <span className="text-white font-bold text-xl leading-tight ml-2">FortuneTalk</span>
        </Link>

        {/* Menu and Wallet */}
        <div className="hidden lg:flex space-x-8">
          <ul className="flex space-x-8 items-center">
            {menuItems.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className="text-sm text-white font-semibold"
                  activeClassName="text-white underline"
                  style={{ textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)' }}

                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
          <Link to="/add-money">
            <div className="relative h-20 w-16 -mt-10 flex flex-col items-center justify-center cursor-pointer">
              <div className="flex items-center justify-center relative mt-9 z-10 transition-transform duration-300 transform hover:scale-105 pop-effect">
                <img src={walletBg} alt="Wallet BG" className="h-[30px] w-auto bg-inherit" />
                <div className="absolute text-black text-sm font-extrabold p-10">
                  {showNumber(customerData?.walletBalance || 0)}
                </div>
              </div>
              <div className="flex items-center justify-center relative -mt-2 z-0 transition-transform duration-300 transform hover:scale-105">
                <img src={Wallet} alt="Wallet" className="h-10 w-10" />
              </div>
            </div>
          </Link>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden">
          <Menu onClick={toggleMenu} className="h-6 w-6 cursor-pointer text-white" />
        </div>

        {/* Mobile Menu */}
        {/* {isMenuOpen && (
          <div ref={modalRef} className="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition lg:hidden menu-open">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pb-6 pt-5">
                <div className="flex items-center justify-between">
                  <Link to="/" className="inline-flex items-center space-x-2">
                    <img src={Logo} alt="Logo" className="h-[73px] w-[73px]" />
                    <span className="font-bold">FortuneTalk</span>
                  </Link>
                  <button
                    type="button"
                    onClick={toggleMenu}
                    className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <X className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6">
                  {isLoggedIn ? (
                    <nav className="grid gap-y-4">
                      {menuItems.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className="-m-3 flex items-center rounded-md p-3 font-semibold hover:bg-gray-50"
                          activeClassName="bg-gray-200 text-black"
                          onClick={() => {
                            closeModal(); 
                            navigate(item.href);
                          }}
                        >
                          <span className="ml-3">{item.name}</span>
                        </NavLink>
                      ))}
                      <button
                        type="button"
                        className="rounded-md bg-gradient-to-r from-[#FFFFFF] to-[#E6E4E4] w-28 px-3 py-2 text-sm font-semibold text-[#F27806] shadow-sm hover:bg-black/80"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </nav>
                  ) : (
                    <button
                      type="button"
                      className="rounded-md bg-gradient-to-r from-[#FFFFFF] to-[#E6E4E4] w-28 px-3 py-2 text-sm font-semibold text-[#F27806] shadow-sm hover:bg-black/80"
                      onClick={openModal}
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )} */}

        {isMenuOpen && (
          <div ref={modalRef} className="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition lg:hidden menu-open">
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pb-6 pt-5">
                <div className="flex items-center justify-between">
                  <Link to="/" className="inline-flex items-center space-x-2">
                    <img src={Logo} alt="Logo" className="h-[73px] w-[73px]" />
                    <span className="font-bold">FortuneTalk</span>
                  </Link>
                  <button
                    type="button"
                    onClick={toggleMenu}
                    className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500"
                  >
                    <span className="sr-only">Close menu</span>
                    <X className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-6">
                  {isLoggedIn ? (
                    <nav className="grid gap-y-4">
                      {menuItems.map((item) => (
                        <p
                          key={item.name}
                          onClick={() => {
                            toggleMenu(); // Close the menu first
                            navigate(item.href); // Then navigate to the page
                          }}
                          className="-m-3 flex items-center rounded-md p-3 font-semibold hover:bg-gray-50"
                        >
                          <span className="ml-3">{item.name}</span>
                        </p>
                      ))}
                      <button
                        type="button"
                        className="rounded-md bg-gradient-to-r from-[#FFFFFF] to-[#E6E4E4] w-28 px-3 py-2 text-sm font-semibold text-[#F27806] shadow-sm hover:bg-black/80"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </nav>
                  ) : (
                    <button
                      type="button"
                      className="rounded-md bg-gradient-to-r from-[#FFFFFF] to-[#E6E4E4] w-28 px-3 py-2 text-sm font-semibold text-[#F27806] shadow-sm hover:bg-black/80"
                      onClick={openModal}
                    >
                      Login
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Login Modal */}
      <Login
        isOpen={isModalOpen}
        onClose={closeModal}
        onLoginSuccess={handleLoginSuccess}
        ref={modalRef} // Pass ref to modal
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  customerData: state.customer.customerData,
});

export default connect(mapStateToProps)(Navbar);
