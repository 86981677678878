import React from "react";
import bannerwallet from "../../assets/image/bannerwallet.jpeg";
import bannerpatla from "../../assets/image/bannerpatla.jpg";
import Carousel from "./Carousel";

const slides = [bannerpatla];

function Hero() {
  return (
    // <div className="flex justify-center items-center h-full bg-gradient-to-r from-[#615F5DFA] to-[#000000]">
    <div className="flex justify-center items-center h-full bg-[rgba(0,0,0,0.9)]">
      <div className="relative w-full max-w-6xl my-4 md:my-6 lg:my-10 mx-4 md:mx-6 lg:mx-10 rounded-3xl overflow-hidden"
      style={{ boxShadow: "0 2px 5px rgba(150, 150, 150, 0.8), 0 -2px 5px rgba(150, 150, 150, 0.8)" }}
       >
        <Carousel autoSlide={true}>
          {slides.map((s, index) => (
            <img
              key={index}
              src={s}
              className="h-auto max-h-96 w-full object-cover object-center rounded-3xl"
             
              alt={`slide-${index}`}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Hero;


// import React from "react";
// import banner1 from "../../assets/image/banner1.png";
// import banner2 from "../../assets/image/bannerpatla.png";
// import bannerwallet from "../../assets/image/bannerwallet.jpg";
// import Carousel from "./Carousel";

// const slides = [bannerwallet];

// function Hero() {
//   return (
//     <div className="flex justify-center items-center h-full">
//       <div className="h-auto sm:h-auto md:h-auto lg:h-auto w-full relative overflow-hidden">
//         <Carousel autoSlide={true}>
//           {slides.map((s, index) => (
//             <img
//               key={index}
//               src={s}
//               className="h-full w-full object-cover object-center"
//               alt={`slide-${index}`}
//             />
//           ))}
//         </Carousel>
//       </div>
//     </div>
//   );
// }

// export default Hero; 


